const interactiveModules = {
    init: function() {
        document.querySelectorAll('.wide-interactive')?.forEach(wideSection => {
            const wideSectionTabs = wideSection.querySelectorAll('.wide-interactive__tab');

            if (wideSectionTabs.length !== 0) {
                const videoContainer = wideSection.querySelector('.wide-interactive__video');
                this.setInteractiveWideTabs(videoContainer, wideSectionTabs, wideSection);

                if ( videoContainer ) {
                    this.automaticlySwitchTabs(videoContainer, wideSection);
                }
            }
        })

        document.querySelectorAll('.interactive-checklist')?.forEach(checklistSection => {
            this.setInteractiveChecklistTabs(checklistSection);
        })
    },
    setInteractiveWideTabs: (videoContainer, wideSectionTabs, wideSection) => {
        wideSectionTabs.forEach( wideSectionTab => {
            wideSectionTab.addEventListener('click', () => interactiveModules.clickWideBehaviour(wideSectionTab, videoContainer, wideSection));
        } )
    },
    clickWideBehaviour: (tab, videoContainer, wideSection) => {
        const prevActiveTab = wideSection.querySelector('.wide-interactive__tab.var_active');
        prevActiveTab.classList.remove('var_active');
        tab.classList.add('var_active');

        if ( videoContainer ) {
            const tabTimeline = tab.getAttribute('data-time');
            const video = videoContainer.querySelector('video');

            video.currentTime = tabTimeline;
        } else {
            const tabIndex = tab.getAttribute('data-index');
            const currentImage = wideSection.querySelector(".wide-interactive__image.var_active");
            const image = wideSection.querySelector(`.wide-interactive__image[data-index="${tabIndex}"]`);

            currentImage.classList.remove('var_active');
            image.classList.add('var_active');
        }
    },
    automaticlySwitchTabs: (videoContainer, wideSection) => {
        const video = videoContainer.querySelector('video');
        const scrollWrapper = wideSection.querySelector('.wide-interactive__tabs');
        const tabsWrapper = wideSection.querySelector('.wide-interactive__wrapper');
        const spaceBetweenTabs = parseFloat(window.getComputedStyle(tabsWrapper).gap);

        const tabsTimelineArray = [];

        const tabs = wideSection.querySelectorAll('.wide-interactive__tab');

        tabs.forEach(tab => tabsTimelineArray.push(tab.getAttribute('data-time')));

        let oldIndex = 0;

        video.addEventListener('timeupdate', event => {
            const currentTime = video.currentTime;
            const currentTabIndex = tabsTimelineArray?.findLastIndex(tabTimeline => currentTime >= tabTimeline);

            if ( oldIndex !== currentTabIndex ) {
                const currentActiveTab = wideSection.querySelector('.wide-interactive__tab.var_active');
                currentActiveTab.classList.remove('var_active');
                tabs[currentTabIndex].classList.add('var_active');
    
                scrollWrapper.scrollLeft = currentActiveTab.offsetWidth + scrollWrapper.scrollLeft + spaceBetweenTabs;
    
                if (currentTabIndex === 0) {
                    scrollWrapper.scrollLeft = 0;
                }
            }

            oldIndex = currentTabIndex;
        })         
    },
    setInteractiveChecklistTabs: (checklistSection) => {
        const tabs = checklistSection.querySelectorAll('.interactive-checklist__tab');

        tabs.forEach(tab => tab.addEventListener('click', () => interactiveModules.switchInteractiveChecklistTimeline(tab, checklistSection)))
    },
    switchInteractiveChecklistTimeline: (tab, checklistSection) => {
        const clickedIndex = tab.getAttribute('data-index');

        const currentActiveTimeline = checklistSection.querySelector('.interactive-checklist__timeline.var_active');
        const currentActiveTab = checklistSection.querySelector('.interactive-checklist__tab.var_active');
        const currentActiveVideo = currentActiveTimeline.querySelector('video');

        const nextActiveTimeline = checklistSection.querySelector(`.interactive-checklist__timeline[data-index="${ clickedIndex }"]`);
        const nextActiveVideo = nextActiveTimeline.querySelector('video');

        currentActiveTimeline.classList.remove('var_active');
        currentActiveTab.classList.remove('var_active');
        currentActiveVideo?.pause();

        nextActiveTimeline.classList.add('var_active');
        tab.classList.add('var_active');
        nextActiveVideo?.play();
    }
}


window.addEventListener('DOMContentLoaded', () => interactiveModules.init())
